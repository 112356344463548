exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-availability-tsx": () => import("./../../../src/pages/admin/availability.tsx" /* webpackChunkName: "component---src-pages-admin-availability-tsx" */),
  "component---src-pages-admin-events-tsx": () => import("./../../../src/pages/admin/events.tsx" /* webpackChunkName: "component---src-pages-admin-events-tsx" */),
  "component---src-pages-admin-notification-tsx": () => import("./../../../src/pages/admin/notification.tsx" /* webpackChunkName: "component---src-pages-admin-notification-tsx" */),
  "component---src-pages-admin-orders-tsx": () => import("./../../../src/pages/admin/orders.tsx" /* webpackChunkName: "component---src-pages-admin-orders-tsx" */),
  "component---src-pages-admin-products-tsx": () => import("./../../../src/pages/admin/products.tsx" /* webpackChunkName: "component---src-pages-admin-products-tsx" */),
  "component---src-pages-admin-promotions-tsx": () => import("./../../../src/pages/admin/promotions.tsx" /* webpackChunkName: "component---src-pages-admin-promotions-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-catering-tsx": () => import("./../../../src/pages/catering.tsx" /* webpackChunkName: "component---src-pages-catering-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-courses-desserts-tsx": () => import("./../../../src/pages/courses/desserts.tsx" /* webpackChunkName: "component---src-pages-courses-desserts-tsx" */),
  "component---src-pages-courses-mains-tsx": () => import("./../../../src/pages/courses/mains.tsx" /* webpackChunkName: "component---src-pages-courses-mains-tsx" */),
  "component---src-pages-courses-sides-tsx": () => import("./../../../src/pages/courses/sides.tsx" /* webpackChunkName: "component---src-pages-courses-sides-tsx" */),
  "component---src-pages-courses-starters-tsx": () => import("./../../../src/pages/courses/starters.tsx" /* webpackChunkName: "component---src-pages-courses-starters-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-markets-tsx": () => import("./../../../src/pages/markets.tsx" /* webpackChunkName: "component---src-pages-markets-tsx" */),
  "component---src-pages-meals-tsx": () => import("./../../../src/pages/meals.tsx" /* webpackChunkName: "component---src-pages-meals-tsx" */),
  "component---src-pages-payment-failure-tsx": () => import("./../../../src/pages/payment-failure.tsx" /* webpackChunkName: "component---src-pages-payment-failure-tsx" */),
  "component---src-pages-payment-success-tsx": () => import("./../../../src/pages/payment-success.tsx" /* webpackChunkName: "component---src-pages-payment-success-tsx" */),
  "component---src-pages-pre-order-tsx": () => import("./../../../src/pages/pre-order.tsx" /* webpackChunkName: "component---src-pages-pre-order-tsx" */),
  "component---src-pages-specials-tsx": () => import("./../../../src/pages/specials.tsx" /* webpackChunkName: "component---src-pages-specials-tsx" */),
  "component---src-pages-spring-rolls-tsx": () => import("./../../../src/pages/spring-rolls.tsx" /* webpackChunkName: "component---src-pages-spring-rolls-tsx" */),
  "component---src-pages-thursday-take-away-tsx": () => import("./../../../src/pages/thursday-take-away.tsx" /* webpackChunkName: "component---src-pages-thursday-take-away-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

